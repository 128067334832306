import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import { Container, Resources, deviceMin, SubmitBtn } from "../components/Primitives"
import meta from "../../content/data/meta.json"
import ResourceHero from "../components/Resources/resourceHero"
import { deviceMax } from "../components/Primitives"
import styled from "styled-components"
import applyJobs from "../images/apply-job.jpg"
import emailjs from "emailjs-com"
import Swal from "sweetalert2"

function encode(data) {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}
const JobsPage = ({ data }) => {
  //   const keywords = data.allMarkdownRemark.edges.map(jobSeeker => {
  //     return jobSeeker.node.frontmatter.keywords
  //   })

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [contactNo, setContactNo] = useState("")
  const [experience, setExperience] = useState("")
  const [qualification, setQualification] = useState("")
  const [applyingForPosition, setApplyingForPosition] = useState("")
  const [currentSalary, setCurrentSalary] = useState("")
  const [expectedSalary, setExpectedSalary] = useState("")
  const [cv, setCV] = useState("")

  const handleNameChange = event => {
    setName(event.currentTarget.value)
  }
  const handleEmailChange = event => {
    setEmail(event.currentTarget.value)
  }
  const handleAddressChange = event => {
    setAddress(event.currentTarget.value)
  }
  const handleCityChange = event => {
    setCity(event.currentTarget.value)
  }
  const handleContactNoChange = event => {
    setContactNo(event.currentTarget.value)
  }
  const handleExperienceChange = event => {
    setExperience(event.currentTarget.value)
  }
  const handleQualificationChange = event => {
    setQualification(event.currentTarget.value)
  }
  const handleApplyingForPositionChange = event => {
    setApplyingForPosition(event.currentTarget.value)
  }
  const handleCurrentSalaryChange = event => {
    setCurrentSalary(event.currentTarget.value)
  }
  const handleExpectedSalaryChange = event => {
    setExpectedSalary(event.currentTarget.value)
  }
  const handleCVChange = event => {
    // console.log(event.currentTarget.files[0])
    if( event.currentTarget.files[0]) {
      if(event.currentTarget.files[0].size > 200000) {
        alert("File is too big!");
      } else {
        setCV(event.currentTarget.files[0])
      }
    } else {
      setCV(event.currentTarget.files[0])
    }
    // console.log(cv)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        name: name,
        email: email,
        address: address,
        city: city,
        contactNo: contactNo,
        experience: experience,
        qualification: qualification,
        applyingForPosition: applyingForPosition,
        currentSalary: currentSalary,
        expectedSalary: expectedSalary,
        cv: cv
      }),
    })
      .then(() =>{
        Swal.fire("Thanks!", "We will get back to you soon", "success")
      })
      .catch(err => {
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occurred:",
          err
        )
        Swal.fire(
          "Oopss...",
          "Looks like you missed some thing, try submitting another form",
          "error"
        )
      })
  }

  // const handleSubmit = e => {
  //   e.preventDefault()
  //   const templateId = "template_owdk6X9p"
  //   setName("")
  //   setEmail("")
  //   setAddress("")
  //   setCity("")
  //   setContactNo("")
  //   setExperience("")
  //   setQualification("")
  //   setApplyingForPosition("")
  //   setCurrentSalary("")
  //   setExpectedSalary("")
  //   setCV("")
  //
  //   sendFeedback(templateId, e.target)
  // }

  function sendFeedback(templateId, form) {
    emailjs
      .sendForm("gmail", templateId, form, "user_bQxczeMAkeLROJb3xebPP")
      .then(res => {
        console.log("Email successfully sent!")
        Swal.fire("Thanks!", "We will get back to you soon", "success")
      })
      .catch(err => {
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occurred:",
          err
        )
        Swal.fire(
          "Oopss...",
          "Looks like you missed some thing, try submitting another form",
          "error"
        )
      })
  }

  return (
    <Layout>
      <SEO
        pathName="/apply-jobs"
        title="Jobs"
        // keywords={keywords}
      />
      <ResourceHero
        color="rgb(184 133 142 / 52%)"
        title="Apply for a job"
        subTitle="We are trusted by graduates for their career uplifting and software companies to upgrade their human capital"
        image={applyJobs}
      />

      <Body>
        <H1_Attn id="about">Job Application Form</H1_Attn>
        <form className="was-validated" name="job-application" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={handleSubmit}>
          <input type="hidden" name="form-name" value="job-application" />
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Enter full name"
                value={name}
                onChange={handleNameChange}
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="Enter email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Address</label>
              <input
                type="text"
                className="form-control"
                id="inputAddress"
                name="address"
                placeholder="Enter address"
                value={address}
                onChange={handleAddressChange}
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label>City</label>
              <input
                type="text"
                className="form-control"
                id="inputCity"
                name="city"
                placeholder="Enter city"
                value={city}
                onChange={handleCityChange}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Contact No. <i>(Must be 11 digits)</i></label>
              <input
                type="tel"
                pattern="[0-9]{11}"
                className="form-control"
                id="contact"
                name="contactNo"
                placeholder="Enter contact number"
                value={contactNo}
                onChange={handleContactNoChange}
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label>Experience <i>(No. of years)</i></label>
              <input
                type="number"
                min={0}
                className="form-control"
                id="experience"
                name="experience"
                placeholder="Enter your experience"
                value={experience}
                onChange={handleExperienceChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Qualification</label>
              <input
                type="text"
                className="form-control"
                id="qualification"
                name="qualification"
                placeholder="Enter your qualification"
                value={qualification}
                onChange={handleQualificationChange}
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label>Applying for Position</label>
              <input
                type="text"
                className="form-control"
                id="applyForPosition"
                name="applyingForPosition"
                placeholder="Enter position of job"
                value={applyingForPosition}
                onChange={handleApplyingForPositionChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Current Salary <i>(PKR)</i></label>
              <input
                type="number"
                min={0}
                className="form-control"
                id="currentSalary"
                name="currentSalary"
                placeholder="Enter current salary"
                value={currentSalary}
                onChange={handleCurrentSalaryChange}
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label>Expected Salary <i>(PKR)</i></label>
              <input
                type="number"
                min={0}
                className="form-control"
                id="expectedSalary"
                name="expectedSalary"
                placeholder="Enter expected salary"
                value={expectedSalary}
                onChange={handleExpectedSalaryChange}
                required
              />
            </div>
          </div>
          <div className="input-group mb-3">
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="inputGroupFile02"
                name="cv"
                accept=".pdf, .doc, .docx, .odt"
                onChange={handleCVChange}
                required
              />
              <label
                className="custom-file-label"
                aria-describedby="inputGroupFileAddon02"
              >
                { cv? cv.name : 'Upload CV (Should be less than 200kb)'}
              </label>
            </div>
            {/*<div className="input-group-append">*/}
            {/*  <span className="input-group-text" id="inputGroupFileAddon02">*/}
            {/*    Upload*/}
            {/*  </span>*/}
            {/*</div>*/}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "2%",
              paddingBottom: "10%"
            }}
          >
            <SubmitBtn
              style={{
                background: " #85083E",
                color: "white",
                padding: "1% 5%"
              }}
              type="submit"
              className="btn "
            >
              <b>Send</b>
            </SubmitBtn>
          </div>
        </form>
      </Body>
    </Layout>
  )
}

export default JobsPage

const H1_Attn = styled.h1`
  text-align: center;
  color: #85083e;
  margin: 50px 0;
  text-transform: uppercase;
`
const Body = styled.div`
  width: 60%;
  margin: auto;
  @media (max-width: 768px) {
    width: 90%;
  }
`
const Btn = styled.button`
  background: var(--softRed);
`
